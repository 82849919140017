import React, {useState} from "react";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import {
  LicenseSlide,
  MindMapSlide,
  SignUpSlide,
  AgreementSlide
} from "./slides";
import { SuspenseLoader } from "./components/Suspense";
import { Layout } from "./styled/layout.styled";

const App = () => {
  const [error, setError] = useState(null);

  //   console.log("lastMessage", lastJsonMessage);

  return (
    <Router>
      <Layout>
        {error && (
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              textAlign: "center"
            }}
          >
            <p style={{ color: "red", fontWeight: "bold" }}>{error}</p>
          </div>
        )}
        <div>
          <Routes>
            <Route
              path="/"
              element={
                <SuspenseLoader>
                  <LicenseSlide onError={setError} />
                </SuspenseLoader>
              }
            />
            <Route
              path="/license"
              element={
                <SuspenseLoader>
                  <LicenseSlide onError={setError} />
                </SuspenseLoader>
              }
            />
            <Route
              path="/signup"
              element={
                <SuspenseLoader>
                  <SignUpSlide />
                </SuspenseLoader>
              }
            />
            <Route
              path="/agreement"
              element={
                <SuspenseLoader>
                  <AgreementSlide />
                </SuspenseLoader>
              }
            />
            <Route
              path="/map"
              element={
                <SuspenseLoader>
                  <MindMapSlide />
                </SuspenseLoader>
              }
            />
          </Routes>
        </div>
      </Layout>
    </Router>
  );
};
// 4BMSw2otDxbEfowH06ILx3zg6c9YFdgXwzhsCOY67AG26Pf7aSqpgJP6wzFPLR2O
export default App;
