/**
 * Урл проверки лицензии
 */
export const licenseUrl = "https://license.gochain.space/license/check/";

export const statspyUrl = "https://statspy.lineclub.ru/structure/";

export const websocketUrl = "wss://dispatcher.lineclub.ru/structure";
// export const websocketUrl = "ws://dispatcher.lineclub.ru/structure/struct_nodes";

export const structNodestUrl = "https://dispatcher.gochain.space:8443";

export const websocketLightUrl = "ws://dispatcher.lineclub.ru/structure";
