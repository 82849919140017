import { combineReducers, configureStore } from "@reduxjs/toolkit";

import { topicsApi } from "./features/topics";

const reducerCombine = combineReducers({
  [topicsApi.reducerPath]: topicsApi.reducer
});

export const store = configureStore({
  reducer: reducerCombine,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      thunk: true,
      immutableCheck: true,
      serializableCheck: false
    }).concat([topicsApi.middleware]);
  }
});
